import React from 'react'
import { graphql } from 'gatsby'
import { Grid, Box } from 'react-raster'
import gridProps from 'layouts/gridProps'
import SEO from '../seo'

const Impressum = ({ data: { seo, allImpressum }, location }) => {
  return (
    <>
      <SEO
        title={'Imprint | ' + seo.title}
        description={seo.description}
        url={location.url}
      />
      <Grid
        {...gridProps}
        gutterY={0}
        top={['80vh', '80vh', '85vh', '85vh', '95vh']}
        bottom={'10vw'}
      >
        <Box cols={12}>
          {allImpressum.nodes.map(({ headline, text }) => (
            <>
              {headline && (
                <Box cols={[12, 12, 4]} bottom={[0, 0, 1]}>
                  <h2 className='studio__headline size--0'>{headline}</h2>
                </Box>
              )}
              <Box
                cols={headline
                  ? [11, 11, 8]
                  : 12
                }
                left={[1, 1, 0]}
                bottom={1}
              >
                <div
                  className='studio__text size--0'
                  dangerouslySetInnerHTML={{ __html: text }}
                />
              </Box>
            </>
          ))}
        </Box>
      </Grid>
    </>
  )
}

export default Impressum

export const query = graphql`
  query {
    seo {
      title
      description
    }
    allImpressum {
      nodes {
        headline
        text
      }
    }
  }
`
